export const COUNTRIES = [
  {
    isoCode: 'ar',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'at',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'au',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'be',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'ca',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'ch',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'cl',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'cz',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'de',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'es',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'fr',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'gb',
    purchaseRegion: 'uk'
  },
  {
    isoCode: 'it',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'md',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'nz',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'pl',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'ro',
    purchaseRegion: 'eu'
  },
  {
    isoCode: 'other',
    purchaseRegion: 'eu'
  }
];
