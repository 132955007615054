const rules = ['de', 'en-GB', 'es', 'fr', 'it'].reduce((prev, current) => {
  return {
    ...prev,
    [current]: {
      currencyFormatUSD: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol'
      },
      currencyFormatEUR: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'
      },
      currencyFormatGBP: {
        style: 'currency',
        currency: 'GBP',
        currencyDisplay: 'symbol'
      },
      meterFormat: {
        style: 'unit',
        unit: 'meter',
        unitDisplay: 'short'
      },
      feetFormat: {
        style: 'unit',
        unit: 'foot',
        unitDisplay: 'narrow'
      },
      inchFormat: {
        style: 'unit',
        unit: 'inch',
        unitDisplay: 'narrow'
      },
      hectareFormat: {
        style: 'unit',
        unit: 'hectare',
        unitDisplay: 'short'
      },
      percentageFormat: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      signedPercentageFormat: {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        signDisplay: 'always'
      },
      genericDecimalFormat: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      yearFormat: {
        style: 'unit',
        unit: 'year',
        unitDisplay: 'long',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    }
  }
}, {});

export default rules;
