import { createI18n } from 'vue-i18n';

import datetimeFormats from './rules/datetime.js';
import numberFormats from './rules/numbers.js';

import enGBMessages from './locales/en-GB.json';

export const i18n = createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    'en-GB': enGBMessages,
  },
  datetimeFormats: datetimeFormats,
  numberFormats: numberFormats
});
