let config;

if (import.meta.env.MODE === 'development') {
  config = {
    api: {
      endpoint: 'http://127.0.0.1:3000',
      key: import.meta.env.VITE_API_KEY
    }
  };
} else {
  config = {
    api: {
      endpoint: (import.meta.env.VITE_API_ENDPOINT.at(-1) === '/')
        ? import.meta.env.VITE_API_ENDPOINT.slice(0, -1)
        : import.meta.env.VITE_API_ENDPOINT,
      key: import.meta.env.VITE_API_KEY
    }
  };
}

config = {
  ...config,
  trackingCodes: {
    facebook: {
      pixelId: import.meta.env.VITE_FACEBOOK_PIXEL_ID
    }
  },
  testimonialVideoIds: {
    fr: import.meta.env.VITE_TESTIMONIAL_FR_VIDEO_ID,
    fallback: import.meta.env.VITE_TESTIMONIAL_FALLBACK_VIDEO_ID
  }
};

export default config;
