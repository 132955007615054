import { nextTick } from 'vue';

import { i18n } from './index.js';

const TranslationUtils = {

  get supportedLocales() {
    return import.meta.env.VITE_SUPPORTED_LOCALES.split(',');
  },

  get currentLocale() {
    return i18n.global.locale.value;
  },
  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale;
  },

  get defaultLocale() {
    return import.meta.env.VITE_DEFAULT_LOCALE;
  },

  isLocaleSupported(locale) {
    return TranslationUtils.supportedLocales.includes(locale);
  },
  
  guessDefaultLocale() {
    const userPersistedLocale = TranslationUtils.getPersistedLocale();
    if (userPersistedLocale) {
      return userPersistedLocale;
    }
    const userPreferredLocale = TranslationUtils.getUserLocale();
    if (TranslationUtils.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }
    if (TranslationUtils.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion;
    }
    return TranslationUtils.defaultLocale;
  },

  getUserLocale() {
    const locale =
      window.navigator.language ||
      window.navigator.userLanguage ||
      TranslationUtils.defaultLocale;

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0]
    };
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem('user-locale');
    if (TranslationUtils.isLocaleSupported(persistedLocale)) {
      return persistedLocale;
    }
    return null;
  },

  async switchLanguage(newLocale) {
    await TranslationUtils.loadLocaleMessages(newLocale);
    TranslationUtils.currentLocale = newLocale;
    document.querySelector('html').setAttribute('lang', newLocale);
    localStorage.setItem('user-locale', newLocale);
  },

  async loadLocaleMessages(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`./locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }
    return nextTick();
  },

  async initLocale(paramLocale) {
    if (!TranslationUtils.isLocaleSupported(paramLocale)) {
      return TranslationUtils.guessDefaultLocale();
    }
    await TranslationUtils.switchLanguage(paramLocale);
    return paramLocale;
  }

};

export default TranslationUtils;
