<script setup>

import { onMounted, ref } from 'vue';
import { Head } from '@unhead/vue/components' 

import config from './config/config';
import { store } from './store';

import TranslationUtils from './i18n/TranslationUtils';

import ApplicationHeader from './components/ApplicationHeader.vue';
import LoadingAnimation from './components/LoadingAnimation.vue';
import Panel from './components/Panel.vue';

const loading = ref(true);

const fbPixelId = import.meta.env.VITE_FACEBOOK_PIXEL_ID;

onMounted(async () => {
  store.checkAuthenticated();

  // Insert FB pixel
  const fbPixelScript = document.createElement('script');
  fbPixelScript.type = 'text/javascript';
  fbPixelScript.text = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${fbPixelId}');
    fbq('track', 'PageView');
  `;
  document.head.appendChild(fbPixelScript);

  await TranslationUtils.switchLanguage(TranslationUtils.guessDefaultLocale());
  loading.value = false;
});

</script>

<template>
  <Head>
    <meta name="description" :content="$t('app.description')" />
    <noscript>
      <img height="1" width="1" style="display:none" :src="`https://www.facebook.com/tr?id={${config.trackingCodes.facebook.pixelId}}&ev=PageView&noscript=1`" />
    </noscript>
  </Head>

  <Suspense>
    <div class="app">
      <div v-if="!loading" class="app__header">
        <ApplicationHeader />
      </div>
      <div class="app__content">
        <main class="page_content">
          <Panel v-if="loading">
            <LoadingAnimation />
          </Panel>
          <router-view v-if="!loading "/>
        </main>
      </div>
    </div>
    <template #fallback>
      Oops&hellip;
    </template>
  </Suspense>
</template>
