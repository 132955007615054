<script>
</script>
<template>
  <div class="loading_animation">
    <div class="loading_animation__image_container">
      <img
        src="/images/loading_animation.svg"
        class="loading_animation__image"
        alt="An infinitely looping animation of a wine being stirred in a glass"
      />
    </div>
    <div class="loading_animation__label_container">
      <span class="loading_animation__label_line_1">{{ $t('common.loaders.labelLine1') }}</span>
      <span class="loading_animation__label_line_2">{{ $t('common.loaders.labelLine2') }}&hellip;</span>
    </div>
  </div>
</template>
