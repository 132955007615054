import './../styles/styles.scss';

import { createApp } from 'vue';
import { createHead } from '@unhead/vue';

import App from './App.vue';
import { i18n } from './i18n';
import { router } from './router';

const app = createApp(App);
const head = createHead();

app
  .use(head)
  .use(i18n)
  .use(router)
  .mount('#app');
