<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Head } from '@unhead/vue/components';

import FormField from './../components/FormField.vue';
import SingleSelectInput from './../components/inputFields/SingleSelectInput.vue';

import TranslationUtils from './../i18n/TranslationUtils';
import { router } from './../router';
import { store } from './../store';
import { COUNTRIES } from './../constants/Countries';
import { buildOptionList, validateRequiredFormValue } from './../utils/InputFieldUtils';

const LANGUAGES = [ 'de', 'en-GB', 'es', 'fr', 'it' ];

const { t } = useI18n();

const error = ref(null);
const processing = ref(false);
const language = ref(TranslationUtils.currentLocale);
const country = ref('');

watch(language, (newLanguage, _) => {
  TranslationUtils.switchLanguage(newLanguage);
});

watch(country, (newCountry, _) => {
  store.setCountry(newCountry);
});

onMounted(() => {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'ViewContent');
  }
});

const countryOpts = computed(() => buildOptionList(COUNTRIES.map(c => c.isoCode), t, 'common.countries'));
const languageOpts = computed(() => buildOptionList(LANGUAGES, t, 'common.languages'));

const onFormSubmit = async (e) => {
  e.preventDefault();

  processing.value = true;

  try {
    if (
      !validateRequiredFormValue(language) ||
      !validateRequiredFormValue(country)
    ) {
      throw new ClientError(ClientError.errorCodes.MISSING_OR_INCORRECT_INPUT_VALUES);
    }

    router.push({
      name: 'NewCalculation'
    });

  } catch (err) {
    if (err instanceof ClientError) {
      error.value = err.code;
    } else {
      error.value = ClientError.errorCodes.UNHANDLED_ERROR;
    }
  } finally {
    processing.value = false;
  }
};

</script>

<template>
  <Head>
    <title>{{ $t('pages.start.meta.title') }}</title>
  </Head>
  <h1>{{ $t('pages.start.title') }}</h1>
  <i18n-t keypath="pages.start.intro" tag="p" scope="global" class="intro">
    <template v-slot:newline><br /></template>
  </i18n-t>
  <form class="input_form" action="#" method="post" @submit="onFormSubmit">
    <i18n-t keypath="pages.start.form.instructions" tag="p" scope="global" class="input_form__instructions">
      <template v-slot:newline><br /></template>
    </i18n-t>

    <form-field
      :label="$t('pages.start.form.fields.language.label')"
      field-id="language"
    >
      <single-select-input
        id="language"
        v-model="language"
        :options="languageOpts"
        :required="true"
        :disabled="processing"
      />
    </form-field>

    <form-field
      :label="$t('pages.start.form.fields.country.label')"
      field-id="country"
    >
      <single-select-input
        id="country"
        v-model="country"
        :options="countryOpts"
        :required="true"
        :disabled="processing"
      />
    </form-field>

    <div class="input_form__actions">
      <ul class="toolbar  toolbar--centered">
        <li class="toolbar__item">
          <button
            type="submit"
            class="button  button--cta"
            :disabled="processing"
          >
            {{ $t('pages.start.form.actions.submit.label') }}
          </button>
        </li>
      </ul>
    </div>
  </form>
</template>
