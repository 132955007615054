const rules = ['de', 'en-GB', 'es', 'fr', 'it'].reduce((prev, current) => {
  return {
    ...prev,
    [current]: {
      short: {
        dateStyle: 'medium',
        timeStyle: 'medium'
      }
    }
  }
}, {});

export default rules;
