import { createRouter, createWebHistory } from 'vue-router';

import { store } from './../store';

import Start from './../views/Start.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '',
      name: 'Start',
      component: Start
    },
    {
      path: '/kiosk',
      name: 'StartKiosk',
      component: Start,
      beforeEnter: (to, from) => {
        store.checkAuthenticated();
        if (!store.isAuthenticated) {
          return { name: 'SignIn', query: { redirect: '/kiosk' } };
        }
        store.setKioskMode();
      }
    },
    {
      path: '/profit-calculation/start',
      name: 'NewCalculation',
      component: () => import('./../views/NewCalculation.vue')
    },
    {
      path: '/profit-calculation/result/:calculationId',
      name: 'CalculationDetails',
      component: () => import('./../views/CalculationDetails.vue')
    },
    {
      path: '/reporting',
      name: 'Reporting',
      component: () => import('./../views/Reporting.vue'),
      beforeEnter: (to, from) => {
        store.checkAuthenticated();
        if (!store.isAuthenticated) {
          return { name: 'SignIn', query: { redirect: '/reporting' } };
        }
      }
    },
    {
      path: '/sign-in',
      name: 'SignIn',
      component: () => import('./../views/SignIn.vue'),
      beforeEnter: (to, from) => {
        store.checkAuthenticated();
        if (store.isAuthenticated) {
          if (store.isAdmin) {
            return { name: 'Reporting' };
          } else {
            return { name: 'StartKiosk' };
          }
        }
      }
    },
    {
      path: '/forbiddgen'
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('./../views/NotFound.vue')
    }
  ]
});

router.beforeEach((to, from) => {
  if (to.name === 'Start' || to.name === 'StartKiosk') {
    store.setCountry(null);
    store.setAdvantageCalculation(null);
  }
  return true;
});

export { router };
