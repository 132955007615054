import { reactive } from 'vue';

export const store = reactive({
  advantageCalculation: null,
  setAdvantageCalculation(value) {
    this.advantageCalculation = value;
  },
  country: null,
  setCountry(value) {
    this.country = value;
  },

  isAuthenticated: false,
  setAuthenticated(value) {
    this.isAuthenticated = value;
    sessionStorage.setItem('isAuthenticated', value.toString());
  },
  checkAuthenticated() {
    this.isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';
    if (this.isAuthenticated) {
      this.checkIsAdmin();
    }
  },
  saveCredentials(userName, userId, pinCode) {
    const credentials = btoa(`${userName}:${userId}:${pinCode}`);
    sessionStorage.setItem('authCredentials', credentials);
  },
  getCredentials() {
    return sessionStorage.getItem('authCredentials');
  },
  clearCredentials() {
    sessionStorage.removeItem('authCredentials');
    sessionStorage.setItem('isAuthenticated', 'false');
    this.isAuthenticated = false;
  },
  isAdmin: false,
  setIsAdmin(value) {
    this.isAdmin = value;
    sessionStorage.setItem('isAdmin', value.toString());
  },
  checkIsAdmin() {
    this.isAdmin = sessionStorage.getItem('isAdmin') === 'true';
  },
  kioskMode: false,
  setKioskMode() {
    this.kioskMode = true;
  },

  purchaseRegion: null,
  setPurchaseRegion(value) {
    this.purchaseRegion = value;
  }
});
