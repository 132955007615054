<script setup>
import InputWrapper from './InputWrapper.vue';

const model = defineModel({ type: String });
defineProps({
  id: { type: String, required: true },
  required: { type: Boolean, default: false },
  disabled: {  type: Boolean, default: false },
  modelValue: { type: String, default: null },
  prefix: { type: String, default: null },
  suffix: { type: String, default: null },
  options: { type: Array, required: true }
});
</script>

<template>
  <InputWrapper
    :prefix="prefix"
    :suffix="suffix"
  >
    <select
      :id="id"
      class="select_input"
      :name="id"
      :disabled="disabled"
      :required="required"
      v-model="model"
    >
      <option
        v-for="(option, i) in options"
        :key="`option-${i}`"
        :value="option.value"
        :selected="option.value === modelValue"
      >
        {{ option.label }}
      </option>
    </select>
  </InputWrapper>
</template>
