export const readFileAsDataURL = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.addEventListener('loadend', (e) => {
        const data = e.target.result;
        resolve(data);
      });

      fileReader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
};

export const validateRequiredFormValue = (val) => {
  return val !== null && val !== '';
};

export const buildOptionList = (values, translateFn, translationKeyPath, sortAlphabetically = true) => {
  const objects = values.map(x => {
    return {
      value: x,
      label: translateFn(`${translationKeyPath}.${x}`)
    };
  });

  if (sortAlphabetically) {
    return objects.toSorted((a, b) => {
      if (a.value === 'other') return 1;
      if (b.value === 'other') return -1;
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });
  } else {
    return objects;
  }
};
